import './css/style.css'
import {Route, Routes} from 'react-router-dom'
import Home from './pages/Home.js'
import Alur from './pages/AlurPendaftaran.js'
import List from './pages/ListPendaftaran.js'
import Registrasi from './pages/FormRegistrasi'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/alur-pendaftaran" element={<Alur/>} />
      <Route path="/list-pendaftaran" element={<List/>} />
      <Route path="/form-registrasi/:id" element={<Registrasi/>} />
    </Routes>
  );
}

export default App;
