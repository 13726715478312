import { useState } from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'


function Registrasi(){
    let { id } = useParams();
    const navigate = useNavigate()
    const [loadings, setLoadings ] = useState() 
    
    const styles = {
        input_reg : 'appearance-none  rounded w-full py-2 px-3 text-grey-darker border border-green-500',
        input_err : 'bg-red-50 border border-red-500  rounded focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400',
        header: ' text-black text-xl border-b border-grey-lighter mb-2',
        label : 'block text-grey-darker text-sm font-bold mb-1',
        btn_orange: 'px-5 py-3 border bg-orange-400 border-orange-500 hover:bg-green-700 hover:border-green-700 text-white font-medium text-sm my-2 btn-zoom rounded-full inline mr-2',
        error_tag : 'text-red-600 text-xs mt-1'
    } 
    const [values, setValues] = useState({
        unit: id,
        nm_ayah : '',
        nm_ibu : '',
        pekerjaan_ayah: '',
        pekerjaan_ibu: '',
        hp_ayah: '',
        hp_ibu: '',
        penghasilan: '',
        nm_anak: '',
        tpt_lahir: '',
        tgl_lahir: '',
        anak_ke: '',
        jml_saudara: '',
        sekolah_asal: '',
        gender: '',
        email: '',
        telephone: '',
        alamat: '',
        rt: '',
        rw: '',
        desa: '',
        kota: '',
    })

    const [errors, setErrors] = useState([])

    const handleChange = (e) => {
        const key = e.target.id
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoadings(1)
        const res = await axios.post('https://adm.ppdb.ihbs.sch.id/api/applicant',values)
        console.log(res);
        if(res.data.msg === 'Success Create'){
            swal("Data Terkirim!", "Bukti Pendaftaran & No Virtual Account Sudah Dikirim Via Email, Silahkan Cek Dan Segera Lakukan Pembayaran", "success").then((value) => {
               navigate("/", { replace: true })
            })
            setLoadings(0)
            setErrors('')
        }else if(res.data.msg === 'Failed Create'){
            setLoadings(0)
            swal("Data Gagal Terkirim!", "Opps something error", "danger")
        }else if(res.data.msg === 'Validation Error'){
            setLoadings(0)
            setErrors(res.data.data.validate_err)
        }
    }
    return (
        <div className='w-screen h-auto lg:h-screen bg-landing-background bg-cover bg-top overflow-auto bg-fixed'>
            <Link to='/' className='absolute inset-x-0 mx-auto w-80 bg-green-500 text-white py-2 text-center rounded-b-full font-bold'>FORM REGISTRASI</Link>
            <div className="max-w-screen-xl px-5 lg:px-8 mx-auto flex flex-col items-start pt-5">
                <div className="bg-opacity-60 bg-white rounded-lg border border-gray-200 text-gray-900 mx-auto w-full my-10">
                    <form onSubmit={handleSubmit} action="POST">
                    <input type="hidden" id='unit' value={values.unit} />
                    <div className='flex flex-col lg:flex-row'>
                        <div className="py-4 px-8 lg:w-1/3">
                            <div className={styles.header}>Data Orang Tua / Wali</div>
                            <div className="mb-4">
                                <label className={styles.label} >Nama Ayah</label>
                                <input className={(errors.nm_ayah ? styles.input_err : styles.input_reg)} id="nm_ayah" name='nm_ayah' type="text" value={values.nm_ayah} onChange={handleChange} />
                                <p className={styles.error_tag}>{errors.nm_ayah}</p>
                            </div>
                            <div className="mb-4">
                                <label className={styles.label} >Nama Ibu</label>
                                <input className={(errors.nm_ibu ? styles.input_err : styles.input_reg)} id="nm_ibu" name='nm_ibu' type="text" value={values.nm_ibu} onChange={handleChange}/>
                                <p className={styles.error_tag}>{errors.nm_ibu}</p>
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 mr-1">
                                    <label className={styles.label} >Pekerjaan Ayah</label>
                                    <select className={(errors.pekerjaan_ayah ? styles.input_err : styles.input_reg)} id="pekerjaan_ayah" name='pekerjaan_ayah' value={values.pekerjaan_ayah} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="PNS">PNS</option>
                                        <option value="Guru/Dosen">Guru/Dosen</option>
                                        <option value="TNI/Polri">TNI/Polri</option>
                                        <option value="Pensiunan">Pensiunan</option>
                                        <option value="Dokter">Dokter</option>
                                        <option value="Politikus">Politikus</option>
                                        <option value="Pengacara">Pengacara</option>
                                        <option value="Pegawai Swasta">Pegawai Swasta</option>
                                        <option value="Wiraswasta">Wiraswasta</option>
                                        <option value="Seniman">Seniman</option>
                                        <option value="Petani/Nelayan">Petani/Nelayan</option>
                                        <option value="Buruh">Buruh</option>
                                        <option value="Lainnya">Lainnya</option>
                                        <option value="Tidak Bekerja">Tidak Bekerja</option>                           
                                    </select>
                                    <p className={styles.error_tag}>{errors.pekerjaan_ayah}</p>
                                </div>
                                <div className='w-1/2 ml-1'>
                                    <label className={styles.label} >Pekerjaan Ibu</label>
                                    <select className={(errors.pekerjaan_ibu ? styles.input_err : styles.input_reg)} id="pekerjaan_ibu" name='pekerjaan_ibu' value={values.pekerjaan_ibu} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="PNS">PNS</option>
                                        <option value="Guru/Dosen">Guru/Dosen</option>
                                        <option value="TNI/Polri">TNI/Polri</option>
                                        <option value="Pensiunan">Pensiunan</option>
                                        <option value="Dokter">Dokter</option>
                                        <option value="Politikus">Politikus</option>
                                        <option value="Pengacara">Pengacara</option>
                                        <option value="Pegawai Swasta">Pegawai Swasta</option>
                                        <option value="Wiraswasta">Wiraswasta</option>
                                        <option value="Seniman">Seniman</option>
                                        <option value="Petani/Nelayan">Petani/Nelayan</option>
                                        <option value="Buruh">Buruh</option>
                                        <option value="Lainnya">Lainnya</option>
                                        <option value="Tidak Bekerja">Tidak Bekerja</option>                           
                                    </select>
                                    <p className={styles.error_tag}>{errors.pekerjaan_ibu}</p>
                                </div>
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 mr-1">
                                    <label className={styles.label} >No HP Ayah</label>
                                    <input className={(errors.hp_ayah ? styles.input_err : styles.input_reg)} id="hp_ayah" name='hp_ayah' type="text" value={values.hp_ayah} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.hp_ayah}</p>
                                </div>
                                <div className='w-1/2 ml-1'>
                                    <label className={styles.label} >No HP Ibu</label>
                                    <input className={(errors.hp_ibu ? styles.input_err : styles.input_reg)} id="hp_ibu" name='hp_ibu' type="text" value={values.hp_ibu} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.hp_ibu}</p>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className={styles.label} >Penghasilan / Bulan</label>
                                <select className={(errors.penghasilan ? styles.input_err : styles.input_reg)} id="penghasilan" name='penghasilan' value={values.penghasilan} onChange={handleChange}>
                                    <option value=""></option>
                                    <option value="Rp. 500.000 sd Rp. 1.000.000">Rp. 500.000 sd Rp. 1.000.000</option>
                                    <option value="Rp. 1.000.000 sd Rp. 3.000.000">Rp. 1.000.000 sd Rp. 3.000.000</option>
                                    <option value="Rp. 3.000.000 sd Rp 5.000.000">Rp. 3.000.000 sd Rp 5.000.000</option>
                                    <option value="Rp. 5.000.000 sd Rp.10.000.000">Rp. 5.000.000 sd Rp.10.000.000</option>
                                    <option value="Rp. 10.000.000 sd Rp. 20.000.000">Rp. 10.000.000 sd Rp. 20.000.000</option>
                                    <option value="Rp. 20.000.000 sd Rp. 50.000.000">Rp. 20.000.000 sd Rp. 50.000.000</option>
                                    <option value=" > Rp. 50.000.000"> &gt; Rp. 50.000.000</option> 
                                </select>
                                <p className={styles.error_tag}>{errors.penghasilan}</p>
                            </div>
                        </div>
                        
                        <div className="py-4 px-8 lg:w-1/3">
                            <div className={styles.header}>Data Calon Peserta Didik</div>
                            <div className="mb-4">
                                <label className={styles.label}>Nama Lengkap</label>
                                <input className={(errors.nm_anak ? styles.input_err : styles.input_reg)} id="nm_anak" name='nm_anak' type="text" value={values.nm_anak} onChange={handleChange}/>
                                <p className={styles.error_tag}>{errors.nm_anak}</p>
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 mr-1">
                                    <label className={styles.label} >Tempat Lahir</label>
                                    <input className={(errors.tpt_lahir ? styles.input_err : styles.input_reg)} id="tpt_lahir" name='tpt_lahir' type="text" value={values.tpt_lahir} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.tpt_lahir}</p>
                                </div>
                                <div className="w-1/2 ml-1">
                                    <label className={styles.label} >Tanggal Lahir</label>
                                    <input className={(errors.tgl_lahir ? styles.input_err : styles.input_reg)} id="tgl_lahir" name='tgl_lahir' type="date" value={values.tgl_lahir} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.tgl_lahir}</p>
                                </div>
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 mr-1">
                                    <label className={styles.label} >Anak Ke</label>
                                    <input className={(errors.anak_ke ? styles.input_err : styles.input_reg)} id="anak_ke" name='anak_ke' type="number" value={values.anak_ke} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.anak_ke}</p>
                                </div>
                                <div className="w-1/2 ml-1">
                                    <label className={styles.label} >Jumlah Saudara</label>
                                    <input className={(errors.jml_saudara ? styles.input_err : styles.input_reg)} id="jml_saudara" name='jml_saudara' type="number" value={values.jml_saudara} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.jml_saudara}</p>
                                </div>
                            </div>
                        <div className="mb-4">
                                <label className={styles.label} >Sekolah Asal</label>
                                <input className={(errors.sekolah_asal ? styles.input_err : styles.input_reg)} id="sekolah_asal" name='sekolah_asal' type="text" value={values.sekolah_asal} onChange={handleChange}/>
                                <p className={styles.error_tag}>{errors.sekolah_asal}</p>
                            </div> 
                            <div className="mb-4">
                                <label className={styles.label} >Gender</label>
                                <select className={(errors.gender ? styles.input_err : styles.input_reg)} id="gender" name='gender' value={values.gender} onChange={handleChange}>
                                    <option></option>
                                    <option value='Laki-laki'>Laki-laki</option>
                                    <option value='Perempuan'>Perempuan</option>
                                </select>
                                <p className={styles.error_tag}>{errors.gender}</p>
                            </div> 
                        </div>

                        <div className="py-4 px-8 lg:w-1/3">
                            <div className={styles.header}>Data Alamat & Kontak</div>
                            <div className="mb-4">
                                <label className={styles.label} >Email</label>
                                <input className={(errors.email ? styles.input_err : styles.input_reg)} id="email" name='email' type="email" placeholder='Gunakan Email yang valid' value={values.email} onChange={handleChange}/>
                                <p className={styles.error_tag}>{errors.email}</p>
                            </div>
                            <div className="mb-4">
                                <label className={styles.label} >No Telephone</label>
                                <input className={(errors.telephone ? styles.input_err : styles.input_reg)} id="telephone" name='telephone' type="text" value={values.telephone} onChange={handleChange}/>
                                <p className={styles.error_tag}>{errors.telephone}</p>
                            </div>
                            <div className="mb-4">
                                <label className={styles.label} >Alamat</label>
                                <textarea className={(errors.alamat ? styles.input_err : styles.input_reg)} id="alamat" name='alamat' type="text" value={values.alamat} onChange={handleChange}></textarea>
                                <p className={styles.error_tag}>{errors.alamat}</p>
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 mr-1">
                                    <label className={styles.label} >RT</label>
                                    <input className={(errors.rt ? styles.input_err : styles.input_reg)} id="rt" name='rt' type="text" value={values.rt} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.rt}</p>
                                </div>
                                <div className="w-1/2 ml-1">
                                    <label className={styles.label} >RW</label>
                                    <input className={(errors.rw ? styles.input_err : styles.input_reg)} id="rw" name='rw' type="text" value={values.rw} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.rw}</p>
                                </div>
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 mr-1">
                                    <label className={styles.label} >Desa / Kelurahan</label>
                                    <input className={(errors.desa ? styles.input_err : styles.input_reg)} id="desa" name='desa' type="text" value={values.kelurahan} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.desa}</p>
                                </div>
                                <div className="w-1/2 ml-1">
                                    <label className={styles.label} >Kota</label>
                                    <input className={(errors.kota ? styles.input_err : styles.input_reg)} id="kota" name='kota' type="text" value={values.kota} onChange={handleChange}/>
                                    <p className={styles.error_tag}>{errors.kota}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center pb-5 lg:pb-0'>
                        <Link to='/list-pendaftaran' className={styles.btn_orange}>
                            BATAL
                        </Link>
                        <button type='submit' className={styles.btn_orange} disabled={loadings}>{(loadings ? 'LOADING ...' : 'KIRIM DATA')}</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Registrasi