import {Link} from 'react-router-dom'
import '../css/style.css';
import herro from '../img/herro.png';
import axios from 'axios';
import { useState, useEffect } from 'react';

function Home(){
    const [homeInfos, setHomeInfos] = useState([])
    useEffect(()=>{
        getHomeInfo();
    },[])

    const getHomeInfo = async () => {
        const res = await axios.get('https://adm.ppdb.ihbs.sch.id/api/content-home')
        setHomeInfos(res.data);
    }
    return(
        <div className='w-full min-h-screen bg-landing-background bg-cover bg-top lg:overflow-hidden bg-fixed'>
            <Link to='/' className='absolute inset-x-0 mx-auto w-80 bg-green-500 text-white py-2 text-center rounded-b-full font-bold'>IBNU HAJAR BOARDING SCHOOL</Link>
            <div className="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start">
                <div className="w-full lg:w-6/12 mt-16 relative">
                <img className="w-9/12 mx-auto" src={herro} alt='ppdb-ihbs'/>
                </div>
                <div className="flex-col w-full lg:w-6/12 justify-center items-start text-center lg:text-left mb-5 my-8 lg:my-44 ">
                    {homeInfos.map((home) => (
                        <div key={home.id}>
                            <h1 data-aos="fade-right" data-aos-once="true" className="text-3xl md:text-6xl font-bold leading-tight text-green-800" key={home.header}>{home.header}
                            </h1>
                            <h1 data-aos="fade-right" data-aos-once="true" className="text-4xl md:text-5xl font-bold leading-tigh text-orange-500" key={home.tahun}>{home.tahun}</h1>
                            <p data-aos="fade-down" data-aos-once="true" data-aos-delay="300" className=" my-2 leading-normal text-xl md:text-3xl mb-3" key={home.note}>{home.note}</p>
                        </div>
                    ))}
                    <div className="flex flex-row justify-center lg:justify-start mt-10 bottom-0">
                        <Link to='/alur-pendaftaran' className="px-4 py-3 border bg-orange-400 border-orange-500 hover:bg-green-700 hover:border-green-700 text-white font-medium text-sm my-2 btn-zoom rounded-full inline mr-2">ALUR PENDAFTARAN</Link>
                        <Link to='/list-pendaftaran' className="px-4 py-3 border bg-orange-400 border-orange-500 hover:bg-green-700 hover:border-green-700 text-white font-medium text-sm my-2 btn-zoom rounded-full inline">DAFTAR SEKARANG</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Home