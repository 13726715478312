import '../css/style.css'
import {Link} from 'react-router-dom'
import head from '../img/head-card.png'
import { useEffect, useState } from 'react'
import axios from 'axios'


function Alur(){
    const styles = {
        card : 'rounded-t-[60px] lg:rounded-t-[46px] rounded-b-md overflow-hidden shadow-lg bg-white relative',
    }
    const [flows, setFlows] = useState([])
    useEffect(() => {
        getFlows()
    },[])
    const getFlows = async () => {
        const res = await axios.get('https://adm.ppdb.ihbs.sch.id/api/flow')
        setFlows(res.data)
    }
    return (
        <div className='w-full h-full md:h-screen lg:h-screen bg-landing-background bg-cover bg-top bg-fixed'>
            <Link to='/' className='absolute inset-x-0 mx-auto w-80 bg-green-500 text-white py-2 text-center rounded-b-full font-bold'>ALUR PENDAFTARAN</Link>
            <div className="max-w-screen-xl px-5 lg:px-8 mx-auto flex flex-row items-start pt-20">
                <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-5 lg:grid-5 xl:grid-5 gap-5">
                    {flows.map((flow) => (
                        <div className={styles.card} key={flow.id}>
                            <h1 className='text-8xl font-bold absolute text-white top-28 left-1/2 -translate-x-1/2 -translate-y-1/2'>{flow.no_urut}</h1>
                            <h1 className='text-xl w-40 text-center font-bold absolute text-white top-44 left-1/2 -translate-x-1/2 -translate-y-1/2'>{flow.title}</h1>
                            <img className="w-full" src={head} alt="1" />
                            <div className="px-3 py-5">
                                <p className="text-base text-center">
                                   {flow.content}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center pb-5 lg:pb-0'>
                <Link to='/' className='px-5 py-3 border bg-orange-400 border-orange-500 hover:bg-green-700 hover:border-green-700 text-white font-medium text-sm my-2 btn-zoom rounded-full inline mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline mr-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                    </svg>
                    BERANDA
                </Link>
                <Link to='/list-pendaftaran' className='px-5 py-3 border bg-orange-400 border-orange-500 hover:bg-green-700 hover:border-green-700 text-white font-medium text-sm my-2 btn-zoom rounded-full inline mr-2'>DAFTAR SEKARANG</Link>
            </div>
        </div>
    );
}

export default Alur