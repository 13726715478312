import '../css/style.css'
import {Link} from 'react-router-dom'
import herro from '../img/herro.png'
import { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'

function List(){
    const [schedules, setSchedules] = useState([])
    useEffect(() => {
        getSchedules()
    },[])
    const getSchedules = async () => {
        const res = await axios.get('https://adm.ppdb.ihbs.sch.id/api/schedule')
        setSchedules(res.data)
    }
    return(
        <div className='w-screen h-auto lg:h-screen bg-landing-background bg-cover bg-top overflow-auto bg-fixed'>
            <Link to='/' className='absolute inset-x-0 mx-auto w-80 bg-green-500 text-white py-2 text-center rounded-b-full font-bold'>JADWAL REGISTRASI</Link>
            <div className="max-w-screen-xl px-4 lg:px-8 mx-auto flex flex-col lg:flex-row items-start">
                <div className="w-full lg:w-6/12 mt-16 relative hidden lg:block">
                    <img className="w-9/12 mx-auto" src={herro} alt='ppdb-ihbs'/>
                </div>
                <div className="flex-col w-full lg:w-6/12 justify-center items-start text-center lg:text-left mb-5 my-28 lg:my-20 text-lg">
                    <div className="bg-opacity-60 bg-white rounded-lg border border-gray-200 text-gray-900 p-5 overflow-auto">
                        <table className="min-w-full">
                            <thead className="border-b">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-6 py-4 text-left">
                                        JENJANG PENDIDIKAN
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-6 py-4 text-center hidden lg:block">
                                        KUOTA
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-6 py-4 text-center">
                                        STATUS
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-6 py-4 text-center">
                                        CHAT
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {schedules.map((schedule) => (
                                    <tr className="border-b" key={schedule.id}>
                                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            <h1 className='text-xl'>{schedule.unit}</h1>
                                            <small className='text-green-600 text-sm block'>{moment(schedule.tgl_mulai).format("DD MMM YYYY")} s/d {moment(schedule.tgl_akhir).format("DD MMM YYYY")}</small>
                                            <small className='text-green-600 text-sm block md:hidden lg:hidden'>Kuota: {schedule.kuota}</small>
                                            <small className='text-green-600 text-sm block'>{(schedule.note ? 'Note: '+ schedule.note : '')}</small>
                                        </td>
                                        <td className="text-gray-900 px-6 py-3 lg:py-2 whitespace-nowrap text-xl text-center hidden lg:block align-middle">
                                            {schedule.kuota}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-3 lg:py-2 whitespace-nowrap text-center">
                                            {(schedule.status == 1 ? 
                                                <Link to={`/form-registrasi/${schedule.id}`} className="px-3 py-3 lg:py-2 border bg-green-700 border-green-700 text-white font-medium text-xs my-2 btn-zoom rounded-full inline mr-2">OPEN</Link> :
                                                <Link to='#' className="px-2 py-3 lg:py-2 border-red-700 bg-red-700 text-white font-medium text-xs my-2 btn-zoom rounded-full inline mr-2">CLOSED</Link>
                                            )}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-3 lg:py-2 whitespace-nowrap text-center">
                                            <a title={schedule.mudir} href={'https://wa.me/'+schedule.telephone} target='_blank' rel='noreferrer'><img width="40" alt="WhatsApp" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/512px-WhatsApp.svg.png"/></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                     <div className='flex justify-center pb-5 lg:pb-0'>
                        <Link to='/' className='px-5 py-3 border bg-orange-400 border-orange-500 hover:bg-green-700 hover:border-green-700 text-white font-medium text-sm my-2 btn-zoom rounded-full inline mr-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline mr-1" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            BERANDA
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List